import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Icon, Button } from 'semantic-ui-react';
import API from 'utils/api';
import Header from 'components/Header';
import Message from 'components/Message';
import MenuProductsList from './ui/MenuProductsList';

class MenuProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
    };
  }
  
  componentDidMount() {
    this.getProducts();
  }
  
  getProducts = () => {
    API.getProducts()
      .then((response) => {
        this.setState({
          menu: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  render() {
    return (
      <div>
        <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
          <Header text="Productos del menú" />
          <Button circular icon primary as={Link} to="/menu/products/new">
            <Icon name="plus" />
          </Button>
        </div>

        { this.state.menu.length === 0 ?
          (
            <Message>
              Aún no tienes ningún producto en tu menú. Da click en el botón de "+" para crear nuevos.
            </Message>
          ) : (
            <MenuProductsList menu={this.state.menu} history={this.props.history} />
          )
        }
      </div>
    )
  }
}
export default withRouter(MenuProductsPage);
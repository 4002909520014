import React, { useEffect } from 'react';
import authHelpers from 'utils/authHelpers';
import { Loader } from 'semantic-ui-react';

export default function LogoutPage() {

    useEffect(() => {
        authHelpers.logout();
        // Redirect to home and refresh the page
        window.location.href = "/";
    }, []);

    return (
        <>
            <div style={{ textAlign: 'center', paddingTop: '2em', paddingBottom: '2em', maxWidth: '400px', margin: '0 auto' }}>
                <Loader active inline='centered' />
                <h3>Cerrando sesión...</h3>
            </div>
        </>
    );
}

import React, { useState, useEffect } from 'react';
import { Card, List } from 'semantic-ui-react';
import Header from 'components/Header';
import Message from 'components/Message';
import API from 'utils/api';
import MicroBillProductMenu from './BillProductMenu';
import CancelGoButtons from 'components/CancelGoButtons';

export default function AddOrdersMenu({ cancelFn, billId, getBill }) {
  const [menu, setMenu] = useState([]);
  const [orders, setOrders] = useState([])
  const [filteredMenu, setFilteredMenu] = useState(menu);
  const [input, setInput] = useState('');

  const addOrders = () => {
    console.log("Sending orders to api to update bill")

    const params = {
      orders: orders.map(order => ({
        product_id: order.product.id,
        quantity: order.quantity
      }))
    };

    console.log("params:", params);

    API.addOrders(params, billId)
      .then((response) => {
        console.log("Bill added new orders")        
        cancelFn();
        getBill();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const addOrUpdateOrder = (product, quantity) => {
    console.log("Attempting to add order in menu: ", product)
    console.log("quantity:", quantity);
    const isOrderInList = orders.some(order => order.product.id === product.id);

    if(isOrderInList) {
      const updatedOrders = orders.map(order => {
        if(order.product.id === product.id) {
          return { ...order, quantity: quantity };
        }
        return order;
      }).filter(order => order.quantity !== 0);
      setOrders(updatedOrders);
    } else if(quantity > 0) {
      setOrders([...orders, { product, quantity }]);
    }
  };

  // load the updated menu
  useEffect(() => {
    API.getProducts()
      .then((response) => {
        setMenu(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // filter menu when input changes
  useEffect(() => {
    const regex = new RegExp(input, 'i');
    const newFilteredMenu = menu.map((categoryProducts) => {
      const category = categoryProducts[0];
      
      const products = categoryProducts[1].filter((product) => {
        return regex.test(product.name)
      });

      if (products.length === 0) {
        return null
      }

      return [category, products]
    });
    const newCleanedUpMenu = newFilteredMenu.filter((item) => item !== null);
    setFilteredMenu(newCleanedUpMenu);
  }, [input, menu])
  
  return (    
    <div style={{ paddingBottom: '2em' }}>
      <Header text="Agregar a la cuenta del menú"/>
      <div>
        { (filteredMenu.length === 0) ? (
            <Message>
              <p>Aún no has agregado productos a tu menú.
                Dale click <a href="/menu/products/new">aquí</a> para agregarlos.
              </p>
            </Message>
          ) : (
            <Card fluid>
              <Card.Content>
                <List divided>
                    { filteredMenu.map((categoryProducts) =>
                      <List.Item
                          key={categoryProducts[0]}
                      >
                        <List.Content style={{ margin: '1em 0', fontSize: '1.3em' }}>
                          <List.Header>
                              { categoryProducts[0] }
                          </List.Header>
                          <List.List>
                              { categoryProducts[1].map((product) => {
                                return (
                                  <MicroBillProductMenu 
                                    product={product} 
                                    billItems={orders} 
                                    addOrUpdateBillItem={addOrUpdateOrder}
                                  />
                                )
                              })}
                          </List.List>
                        </List.Content>
                      </List.Item>
                    )}
                </List>
              </Card.Content>
            </Card>
          )       
        }

        <CancelGoButtons
          cancelFn={ cancelFn }
          goText={"agregar ordenes"}
          goEnabled={ orders.length > 0 ? true : false }
          goFn={addOrders}
        />
      </div>
    </div>
  )
}
import React, { useState } from 'react';
import { Button, Icon, Message } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import authHelpers from 'utils/authHelpers';

export default function GoogleLoginButton({ 
  buttonText = "Iniciar sesión con Google",
  redirectPath = "/bills" 
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  const handleGoogleLogin = async () => {
    setLoading(true);
    setError(null);

    try {
      await authHelpers.loginWithGoogle();
      history.push(redirectPath);
    } catch (error) {
      setError(
        error.message || 
        'No pudimos iniciar sesión con Google. Por favor intenta de nuevo.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button 
        color="red" 
        fluid 
        onClick={handleGoogleLogin}
        loading={loading}
        disabled={loading}
        size="large"
      >
        <Icon name="google" /> {buttonText}
      </Button>
      
      {error && (
        <Message
          error
          content={error}
        />
      )}
    </div>
  );
} 
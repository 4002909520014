import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Header from 'components/Header';
import Message from 'components/Message';
import { Button, Icon } from 'semantic-ui-react';
import BillCard from './ui/BillCard';
import API from 'utils/api';

class BillsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bills: []
    };
  }

  componentDidMount() {
    this.getBills();
  }

  createBill = () => {    
    const params = {
      orders: []	
    };

    API.createBill(params)
      .then((response) => {
        this.props.history.push('/bills');
        this.getBills();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getBills = () => {
    API.getBills()
      .then((response) => {
        this.setState({ bills: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  createBill = () => {
    const params = {};

    API.createBill(params)
      .then((response) => {
        const newBill = response.data;
        this.props.history.push(`/bills/${newBill.id}`);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    if (!this.state.bills) { return null }

    let content = null;
    
    if (this.state.bills.length === 0) {
      content = (
        <Message>
          Aún no tienes cuentas abiertas. Dale click al botón de "+" para abrir una cuenta nueva.
        </Message>
      );
    }
    
    return (
      <div>
        <Header text="Cuentas abiertas">
          <Button
            fluid
            circular
            primary
            icon
            onClick={this.createBill}
          >
            <Icon name='plus' />
          </Button>
        </Header>

        { content }

        <div>
          { this.state.bills.map((bill) => {
            return (
              <BillCard bill={bill} key={bill.id} />
            )
          })}
        </div>
      </div>
    )
  }
}

export default withRouter(BillsPage);
import React from 'react';
import { useLocation } from 'react-router-dom';

const NotFoundPage = () => {
  const location = useLocation();
  
  const pageStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
  };

  return (
    <div style={pageStyle}>
      <h1>404</h1>
      <code style={{ color: 'blue' }}>{location.pathname}</code>
      <p>Page Not Found</p>
    </div>
  );
};

export default NotFoundPage;
import React from 'react';
import { Icon, Image, Button, Grid, Segment, Statistic } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import businesOwnerImage from 'images/business-owner-large.png';
import SignupForm from 'components/SignupForm';

export default function HomePage() {
  return (
    <>
      {/* Hero Section */}
      <div style={{ 
        background: 'linear-gradient(135deg, #1b1b1b 0%, #2d2d2d 100%)',
        color: 'white',
        padding: '4em 1em',
        marginBottom: '3em'
      }}>
        <Grid container stackable verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column width={8}>
              <h1 style={{ 
                fontSize: '3em', 
                marginBottom: '0.5em',
                fontWeight: 'bold'
              }}>
                Administra tu restaurante sin complicaciones
              </h1>
              <p style={{ 
                fontSize: '1.5em', 
                marginBottom: '1.5em',
                lineHeight: '1.4'
              }}>
                Restaurantero te ayuda a controlar órdenes, manejar cuentas y ver tus ganancias en tiempo real.
                <br/>
                <strong>Sin necesidad de capacitación.</strong>
              </p>
              <SignupForm />
              <p style={{ 
                marginTop: '1em',
                fontSize: '1.1em',
                textAlign: 'center'
              }}>
                ¿Ya tienes una cuenta? <Link to="/login" style={{ color: '#2185d0' }}>Inicia sesión</Link>
              </p>
            </Grid.Column>
            <Grid.Column width={8}>
              <Image 
                src={businesOwnerImage}
                size='large'
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover'
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      {/* Social Proof */}
      <Segment basic textAlign='center' style={{ marginBottom: '4em' }}>
        <Grid container stackable columns={3}>
          <Grid.Column>
            <Statistic>
              <Statistic.Value>500+</Statistic.Value>
              <Statistic.Label style={{ color: '#1b1b1b' }}>Restaurantes activos</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Statistic>
              <Statistic.Value>50,000+</Statistic.Value>
              <Statistic.Label style={{ color: '#1b1b1b' }}>Órdenes procesadas</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Statistic>
              <Statistic.Value>15min</Statistic.Value>
              <Statistic.Label style={{ color: '#1b1b1b' }}>Tiempo de configuración</Statistic.Label>
            </Statistic>
          </Grid.Column>
        </Grid>
      </Segment>

      {/* Key Features */}
      <div style={{ padding: '0 1em', marginBottom: '4em' }}>
        <Grid container stackable>
          <Grid.Row>
            <Grid.Column width={16}>
              <h2 style={{ 
                textAlign: 'center', 
                fontSize: '2.5em',
                marginBottom: '1.5em',
                color: '#1b1b1b'
              }}>
                Todo lo que necesitas para administrar tu negocio
              </h2>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5}>
              <Segment padded='very' style={{ height: '100%' }}>
                <Icon name="file alternate outline" size="huge" color="blue" />
                <h3 style={{ color: '#1b1b1b' }}>Control de Órdenes</h3>
                <p>Maneja todas tus órdenes desde un solo lugar. Visualiza el estado de cada orden en tiempo real.</p>
                <ul style={{ paddingLeft: '1.5em', marginTop: '1em' }}>
                  <li>Estado de órdenes en tiempo real</li>
                  <li>Notificaciones automáticas</li>
                  <li>Historial completo</li>
                </ul>
              </Segment>
            </Grid.Column>
            <Grid.Column width={5}>
              <Segment padded='very' style={{ height: '100%' }}>
                <Icon name="dollar sign" size="huge" color="blue" />
                <h3 style={{ color: '#1b1b1b' }}>Gestión de Cuentas</h3>
                <p>Cobra más rápido y reduce errores. Mantén un registro detallado de todas las transacciones.</p>
                <ul style={{ paddingLeft: '1.5em', marginTop: '1em' }}>
                  <li>División de cuentas</li>
                  <li>Historial de pagos</li>
                  <li>Reportes detallados</li>
                </ul>
              </Segment>
            </Grid.Column>
            <Grid.Column width={5}>
              <Segment padded='very' style={{ height: '100%' }}>
                <Icon name="chart bar outline" size="huge" color="blue" />
                <h3 style={{ color: '#1b1b1b' }}>Reportes de Ventas</h3>
                <p>Visualiza el desempeño de tu negocio con reportes claros y detallados.</p>
                <ul style={{ paddingLeft: '1.5em', marginTop: '1em' }}>
                  <li>Ventas diarias y mensuales</li>
                  <li>Productos más vendidos</li>
                  <li>Control de gastos</li>
                </ul>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      {/* Call to Action */}
      <Segment 
        padded='very' 
        style={{ 
          background: '#f5f5f5',
          marginBottom: '4em'
        }}
      >
        <Grid container stackable verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column width={8}>
              <h2 style={{ fontSize: '2em', marginBottom: '0.5em', color: '#1b1b1b' }}>
                Comienza a optimizar tu restaurante hoy
              </h2>
              <p style={{ fontSize: '1.2em', marginBottom: '1em', color: '#1b1b1b' }}>
                Prueba gratuita por 15 días · Sin tarjeta de crédito
              </p>
              <Button 
                primary
                size='huge'
                onClick={() => document.querySelector('input[type="email"]').focus()}
              >
                Comenzar ahora
              </Button>
            </Grid.Column>
            <Grid.Column width={8}>
              <div style={{ 
                background: '#fff',
                padding: '2em',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}>
                <h3 style={{ marginBottom: '1em', color: '#1b1b1b' }}>Lo que obtienes:</h3>
                <p style={{ marginBottom: '0.5em' }}>
                  <Icon name="check" color="blue" /> Control total de órdenes y cuentas
                </p>
                <p style={{ marginBottom: '0.5em' }}>
                  <Icon name="check" color="blue" /> Administración de menú digital
                </p>
                <p style={{ marginBottom: '0.5em' }}>
                  <Icon name="check" color="blue" /> Reportes de ventas detallados
                </p>
                <p style={{ marginBottom: '0.5em' }}>
                  <Icon name="check" color="blue" /> Control de gastos
                </p>
                <p style={{ marginBottom: '0.5em' }}>
                  <Icon name="check" color="blue" /> Soporte personalizado
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      {/* FAQ Section */}
      <div style={{ maxWidth: '800px', margin: '0 auto', padding: '0 1em', marginBottom: '4em' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '1.5em', color: '#1b1b1b' }}>
          Preguntas Frecuentes
        </h2>
        <Segment.Group>
          <Segment>
            <h4 style={{ color: '#1b1b1b' }}>¿Necesito instalar algo?</h4>
            <p>No, Restaurantero funciona 100% en la nube. Solo necesitas un navegador web.</p>
          </Segment>
          <Segment>
            <h4 style={{ color: '#1b1b1b' }}>¿Cómo puedo comenzar a usar Restaurantero?</h4>
            <p>Simplemente regístrate y comienza a usar todas las funciones inmediatamente.</p>
          </Segment>
          <Segment>
            <h4 style={{ color: '#1b1b1b' }}>¿Puedo exportar mis datos?</h4>
            <p>Sí, puedes exportar todos tus reportes y datos en cualquier momento.</p>
          </Segment>
          <Segment>
            <h4 style={{ color: '#1b1b1b' }}>¿Ofrecen soporte técnico?</h4>
            <p>Sí, contamos con soporte personalizado vía chat y correo electrónico.</p>
          </Segment>
        </Segment.Group>
      </div>

      {/* Final CTA */}
      <Segment 
        textAlign='center' 
        padded='very'
        style={{ 
          background: '#2185d0',
          color: 'white'
        }}
      >
        <h2 style={{ fontSize: '2.5em', marginBottom: '0.5em' }}>
          Optimiza tu restaurante ahora
        </h2>
        <p style={{ fontSize: '1.2em', marginBottom: '1.5em' }}>
          Únete a más de 500 restaurantes que ya confían en Restaurantero
        </p>
        <Button 
          size='huge' 
          inverted
          onClick={() => document.querySelector('input[type="email"]').focus()}
        >
          Comenzar ahora
        </Button>
      </Segment>
    </>
  );
}
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Loader, Button, Statistic } from 'semantic-ui-react';
import Header from 'components/Header';
import API from 'utils/api';
import formatter from 'utils/formatter';

class SalesPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      salesData: [],
      isLoading: true,
      error: null,
      salesPeriod: 'daily',
      showPeriodOptions: false
    };
  }

  componentDidMount() {
    this.fetchSalesData();
  }
  
  fetchSalesData = () => {
    console.log(`Fetching ${this.state.salesPeriod} sales data...`);
    this.setState({ isLoading: true });
    
    let apiCall;
    switch(this.state.salesPeriod) {
      case 'weekly':
        apiCall = API.getOwnerWeeklyReports();
        break;
      case 'monthly':
        apiCall = API.getOwnerMonthlyReports();
        break;
      default:
        apiCall = API.getOwnerDailyReports();
    }
    
    apiCall
      .then(response => {
        console.log('Sales data response:', response.data);
        this.setState({
          salesData: response.data,
          isLoading: false
        });
      })
      .catch(error => {
        console.error('Error fetching sales:', error);
        this.setState({
          isLoading: false,
          error: 'Error al cargar las ventas'
        });
      });
  };
  
  handlePeriodChange = (period) => {
    this.setState({ 
      salesPeriod: period,
      showPeriodOptions: false 
    }, () => {
      this.fetchSalesData();
    });
  };

  togglePeriodOptions = () => {
    this.setState(prevState => ({
      showPeriodOptions: !prevState.showPeriodOptions
    }));
  };

  getPeriodTitle = () => {
    switch(this.state.salesPeriod) {
      case 'weekly':
        return 'Ventas por semana';
      case 'monthly':
        return 'Ventas por mes';
      default:
        return 'Ventas por día';
    }
  };

  render() {
    const { salesData, isLoading, error, salesPeriod, showPeriodOptions } = this.state;
    
    if (isLoading) {
      return (
        <div style={{textAlign: 'center', marginTop: '2em'}}>
          <Loader active inline='centered' size='large'>Cargando ventas...</Loader>
        </div>
      );
    }
    
    if (error) {
      return (
        <div style={{textAlign: 'center', marginTop: '2em', color: 'red'}}>
          {error}
        </div>
      );
    }
    
    const totalSales = salesData.reduce((sum, sale) => sum + parseFloat(sale.total || 0), 0);
    const today = new Date();
    const formattedDate = new Intl.DateTimeFormat('es-ES', { 
      day: 'numeric',
      month: 'long'
    }).format(today);
    
    const periodOptions = [
      { key: 'daily', text: 'Día', value: 'daily' },
      { key: 'weekly', text: 'Semana', value: 'weekly' },
      { key: 'monthly', text: 'Mes', value: 'monthly' },
    ];
    
    return (
      <div>
        <Header 
          text={showPeriodOptions ? '' : this.getPeriodTitle()}
          subtitle={formattedDate}
        >
          <div style={{ position: 'relative', display: 'inline-block' }}>
            {showPeriodOptions ? (
              <div 
                style={{
                  display: 'flex',
                  gap: '1rem',
                  alignItems: 'center',
                  padding: '0.5rem',
                  background: 'white',
                  borderRadius: '4px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  marginLeft: '-0.5rem'
                }}
              >
                {periodOptions.map(option => (
                  <span
                    key={option.value}
                    onClick={() => this.handlePeriodChange(option.value)}
                    style={{
                      cursor: 'pointer',
                      padding: '0.5rem 1rem',
                      borderRadius: '4px',
                      fontWeight: salesPeriod === option.value ? 'bold' : 'normal',
                      color: salesPeriod === option.value ? '#2185d0' : 'inherit',
                      transition: 'all 0.2s ease'
                    }}
                    onMouseEnter={e => e.currentTarget.style.backgroundColor = '#f3f4f5'}
                    onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
                  >
                    {option.text}
                  </span>
                ))}
              </div>
            ) : (
              <Button
                circular
                primary
                icon="calendar alternate outline"
                onClick={this.togglePeriodOptions}
              />
            )}
          </div>
        </Header>

        <div style={{marginTop:'1em', textAlign: 'center', marginBottom: '1.5em'}}>
          <Statistic>
            <Statistic.Value>{formatter.currency(totalSales)}</Statistic.Value>
            <Statistic.Label>Total</Statistic.Label>
          </Statistic>
        </div>
        
        <div style={{marginTop:'1em'}}>
          {salesData.length > 0 ? (
            <>
              {salesData.map((sale, index) => (
                <Card fluid key={index}>
                  <Card.Content>
                    <Card.Description>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                          <span style={{ color: 'darkgray' }}>
                            {sale.date}
                          </span>
                          
                          <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
                            {formatter.currency(sale.total)}
                          </span>
                        </div>
                      </div>
                    </Card.Description>
                  </Card.Content>
                </Card>
              ))}
            </>
          ) : (
            <div style={{textAlign: 'center', marginTop: '2em', color: 'gray'}}>
              No hay ventas que mostrar.
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(SalesPage); 
import React from 'react';
import EmailLoginForm from 'components/EmailLoginForm';
import GoogleLoginButton from 'components/GoogleLoginButton';
import { Divider } from 'semantic-ui-react';

export default function LoginPage() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', minHeight: '100vh', alignItems: 'center' }}>
            <div style={{ width: '100%', maxWidth: '400px', padding: '0 1em' }}>                
                <div style={{ textAlign: 'center', marginBottom: '2em' }}>
                    <h1>Inicia sesión en Restaurantero</h1>
                    <p>Elige cómo quieres iniciar sesión.</p>
                </div>
                
                <GoogleLoginButton />
                
                <Divider horizontal>O</Divider>
                
                <EmailLoginForm />
            </div>
        </div>
    );
}

import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { MicroRoute } from 'routes';

import HomePage from 'pages/HomePage'; 

import AuthPage   from 'pages/access/AuthPage';
import LoginPage  from 'pages/access/LoginPage';
import LogoutPage from 'pages/access/LogoutPage';

import MenuCategoriesPage from 'pages/menu/MenuCategoriesPage';
import MenuCategoryForm   from 'pages/menu/MenuCategoryForm';

import MenuProductsPage from 'pages/menu/MenuProductsPage';
import MenuProductForm  from 'pages/menu/MenuProductForm';
import MenuProductPage  from 'pages/menu/MenuProductPage';

import BillsPage from 'pages/bills/BillsPage';
import BillPage  from 'pages/bills/BillPage';

import SalesPage from 'pages/sales/SalesPage';

import AccountPage from 'pages/account/AccountPage';

import NotFoundPage from 'pages/NotFoundPage';

function App(props) {
  const appStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  return (
  
    <BrowserRouter>
      <div id="app" style={appStyle}>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>

          <Route path="/login" exact>
            <LoginPage />
          </Route>

          <Route path="/logout" exact>
            <LogoutPage />
          </Route>

          <Route path="/auth" exact>
            <AuthPage />
          </Route>

          <MicroRoute path="/menu/categories" exact>
            <MenuCategoriesPage />
          </MicroRoute>

          <MicroRoute path="/menu/categories/new" exact>
            <MenuCategoryForm />
          </MicroRoute>

          <MicroRoute path="/menu/products" exact>
            <MenuProductsPage />
          </MicroRoute>

          <MicroRoute path="/menu/products/new" exact>
            <MenuProductForm />
          </MicroRoute>

          <MicroRoute path="/menu/products/:productId" exact>
            <MenuProductPage />
          </MicroRoute>

          <MicroRoute path="/bills" exact>
            <BillsPage />
          </MicroRoute>

          <MicroRoute path="/bills/:billId" exact>
            <BillPage />
          </MicroRoute>

          <MicroRoute path="/sales" exact>
            <SalesPage />
          </MicroRoute>

          <MicroRoute path="/account" exact>
            <AccountPage />
          </MicroRoute>
          
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  )  
}

export default App;
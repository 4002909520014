import React, { useState, useEffect } from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import API from 'utils/api';
import Header from 'components/Header';
import Message from 'components/Message';
import MenuCategoryRow from './ui/MenuCategoryRow';
import { List } from 'semantic-ui-react';

const MenuCategoriesPage = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await API.getProductCategories();
        setCategories(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    getCategories();
  }, []);

  return (
    <div>
      <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
        <Header text="Categorías del menú" />
        <Button circular icon primary as={Link} to="/menu/categories/new">
          <Icon name="plus" />
        </Button>
      </div>

      { categories.length === 0 ?
        (
          <Message>
            Aún no tienes ninguna categoría para tu menú. Da click en el botón de "+" para crear nuevas.
          </Message>
        ) : (
          <div style={{ marginTop: '1em', padding: '1em', backgroundColor: 'whitesmoke' }}>
            <List divided relaxed size="huge">
              { categories.map(category => 
                <MenuCategoryRow category={category} key={category.id} />
              )}
            </List>
          </div>
        )
      }
    </div>
  );
};

export default withRouter(MenuCategoriesPage);
import React, { useState } from 'react';
import { Form, Button, Message, Divider } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import API from 'utils/api';
import GoogleLoginButton from './GoogleLoginButton';

export default function SignupForm() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await API.requestMagicLink({ email });
      setEmailSent(true);
    } catch (error) {
      if (error.response?.status === 409) {
        setError(
          <span>
            {error.response.data.error}{' '}
            <Link to="/login" style={{ textDecoration: 'underline' }}>
              Ir a inicio de sesión
            </Link>
          </span>
        );
      } else {
        setError(
          error.response?.data?.message || 
          'No pudimos crear tu cuenta. Por favor intenta de nuevo.'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  if (emailSent) {
    return (
      <Message positive>
        <Message.Header>¡Revisa tu correo!</Message.Header>
        <p>
          Hemos enviado un enlace para activar tu cuenta a <b>{email}</b>
          <br />
          Haz clic en el enlace para comenzar a usar Restaurantero.
          <br />
          El enlace expirará en 15 minutos.
        </p>
        <Button 
          basic 
          onClick={() => setEmailSent(false)}
          style={{ marginTop: '1em' }}
        >
          Usar otro correo
        </Button>
      </Message>
    );
  }

  return (
    <div>
      <GoogleLoginButton buttonText="Registrarse con Google" />
      
      <Divider horizontal>O</Divider>
      
      <Form onSubmit={handleSubmit} error={!!error}>
        <Form.Field>
          <Form.Input
            type="email"
            placeholder="tu@correo.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            icon="mail"
            iconPosition="left"
            disabled={loading}
            required
          />
        </Form.Field>

        {error && (
          <Message
            error
            content={error}
          />
        )}

        <Button
          primary
          fluid
          loading={loading}
          disabled={loading || !email}
          type="submit"
          size="large"
        >
          Crear cuenta
        </Button>
      </Form>

      <p style={{ 
        fontSize: '0.9em', 
        color: 'gray', 
        marginTop: '1em', 
        textAlign: 'center' 
      }}>
        Al crear una cuenta, aceptas nuestros{' '}
        <a href="/terms" target="_blank" rel="noopener noreferrer">
          términos de servicio
        </a>
      </p>
    </div>
  );
} 
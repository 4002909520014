import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Header, Icon, Label } from 'semantic-ui-react';
import TimeAgo from 'timeago-react';
import formatter from 'utils/formatter';
import { register } from 'timeago.js';
import es from 'timeago.js/lib/lang/es';

export default function BillCard({ bill }) {
  // Register the Spanish locale
  register('es', es);

  const pendingOrders = bill.orders.filter(order => !order.delivered_at);

  let summary = (
    <span style={{ fontSize: '1.5em', marginRight: '5px' }}>
      Sin ordenes pendientes
    </span>
  );

  if (pendingOrders.length === 1) {
    summary = (
      <span style={{ fontSize: '1.5em', marginRight: '5px' }}>
        <span style={{ fontWeight: 'bold' }}>1</span> orden pendiente
      </span>
    );
  }
  
  if (pendingOrders.length > 1) {
    summary = (
      <span style={{ fontSize: '1.5em', marginRight: '5px' }}>
        <span style={{ fontWeight: 'bold' }}>{pendingOrders.length}</span> ordenes pendientes
      </span>
    );
  }

  if (bill.orders.length === 0) {
    summary = (
      <span style={{ fontSize: '1.5em', marginRight: '5px', marginTop: '0.5em' }}>
        Sin ordenes
      </span>
    );
  }

  return (
    <Card
      fluid
      key={bill.id}
      as={Link}
      to={`/bills/${bill.id}`}
      style={{ marginBottom: '1.5em' }}
    >
      <Card.Content
        style={{ cursor: 'pointer' }}
      >
        <Card.Header>
          { bill.name ? bill.name : "Cuenta #" + bill.id }
        </Card.Header>
        <Card.Description>
        <div style={{ display: 'flex', justifyContent: '', alignItems: 'center' }}> 
            <Icon name="clock outline" size='large' />
            <span style={{ fontSize: '1em',  }}>
              Abierta
              {' '}
              <TimeAgo
                datetime={bill.created_at}
                locale="es"
              />
            </span>
          </div>
          
          { bill.orders.length > 0 &&
            <div style={{ margin: '1em 0', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <span style={{ fontSize: '2em', marginRight: '10px' }}>
                { formatter.currency(bill.total) }
              </span>
              <div>
                { bill.paid_at ? (
                  <Label color="green">
                    PAGADA
                  </Label>
                  
                ) : (
                  <Label color="red">
                    SIN PAGAR
                  </Label>
                )}
              </div>
            </div>
          }
          <div style={{ display: 'flex', justifyContent: '', alignItems: 'center' , margin: '0.5em 0 1em 0' }}>
            { summary }
            { (pendingOrders.length === 0) && (bill.orders.length > 0) &&
              <Icon name="check" size="large" color="green" />
            }
          </div>
        </Card.Description>
      </Card.Content>
    </Card>
  )
}
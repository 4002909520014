import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import authHelpers from 'utils/authHelpers';
import Nav from 'ui/Nav';
import PoweredBy from 'ui/PoweredBy';

export const MicroRoute = ({ children, ...remainingProps }) => {
  const token = authHelpers.getToken();
  
  if (token) {
    const component = React.cloneElement(children);
    
    // Flat menu structure
    const links = [
      { name: 'Cuentas Abiertas', url: '/bills' },
      { name: 'Ventas', url: '/sales' },
      { name: 'Categorías del menú', url: '/menu/categories' },
      { name: 'Productos del menú', url: '/menu/products' },
      { name: 'Mi cuenta', url: '/account' }
    ];

    return (
      <Route {...remainingProps}>
        <Nav links={links} />
        <Container style={{ flexGrow: 1 }}>
          { component }
        </Container>
      </Route>
    )
  }
  
  return <Redirect to="/" />
}
import React from 'react';
import { List, Card, Icon } from 'semantic-ui-react';
import formatter from 'utils/formatter';

export default function MenuProductsList({ menu, history }) {
  return menu.map((categoryProducts, index) => (
      <Card fluid key={index}>
        <Card.Content>
          <Card.Header>
            { categoryProducts[0] }
          </Card.Header>
          <Card.Description>
            <List divided>
              { categoryProducts[1].map((product) => (
                <List.Item
                  key={product.id}
                  style={{ cursor: 'pointer', padding: '1em 0' }}
                  onClick={ () => history.push(`/menu/products/${product.id}`) }
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ marginBottom: '0.5em', fontSize: 'large', textDecoration: product.out_of_stock ? 'line-through' : 'none' }}>
                      { product.out_of_stock ? (
                        <Icon name="circle" color="red" />
                      ) : (
                        <Icon name="check circle" color="green" />
                      )}

                      <span>
                        { product.name }
                      </span>
                    </div>
                    <div style={{ marginLeft: '1em', fontSize: '16px' }}>
                      { formatter.currency(product.price) }
                    </div>
                  </div>
                  
                  { product.desc && (
                    <div>
                      { product.desc }
                    </div>
                  )}
                </List.Item>
              ))}
            </List>
          </Card.Description>
        </Card.Content>
      </Card>
    )
  )
}
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';

const Nav = ({ links }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [activeItem, setActiveItem] = useState('Cuentas abiertas');
  const history = useHistory();

  const getIconForLink = (name) => {
    switch (name) {
      case 'Cuentas Abiertas':
        return 'file alternate outline';
      case 'Ventas':
        return 'chart bar outline';
      case 'Categorías del menú':
        return 'list';
      case 'Productos del menú':
        return 'food';
      case 'Mi cuenta':
        return 'user outline';
      default:
        return 'circle outline';
    }
  };

  const handleItemClick = (e, { name, url }) => {
    setShowMenu(false);
    setActiveItem(name);
    history.push(url);
  };

  const toggleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Menu attached="top" borderless compact inverted size="small" color="blue">
        <Menu.Item position='right'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '1em' }}>
              <a href="/bills" style={{ textDecoration: 'none', color: 'white' }}>
                restaurantero
              </a>
            </span>
            <Icon
              name={showMenu ? 'close' : 'bars'}
              size="large"
              style={{ cursor: 'pointer' }}
              onClick={toggleShowMenu}
            />
          </div>
        </Menu.Item>
      </Menu>

      {showMenu && (
        <Menu vertical fluid style={{ 
          position: 'absolute', 
          top: '100%', 
          right: 0, 
          margin: 0,
          width: '250px',
          zIndex: 1000,
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          {links.map((link) => (
            <Menu.Item
              key={link.url}
              name={link.name}
              active={activeItem === link.name}
              onClick={(e, itemProps) => handleItemClick(e, { ...itemProps, url: link.url })}
              style={{ textAlign: 'right' }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                {link.name}
                <Icon name={getIconForLink(link.name)} style={{ marginLeft: '0.5em' }} />
              </div>
            </Menu.Item>
          ))}
        </Menu>
      )}
    </div>
  );
};

export default Nav;
import React from 'react';
import { useHistory } from 'react-router-dom';
import API from 'utils/api';
import Header from 'components/Header';
import { Card, Button, Icon, List } from 'semantic-ui-react';
import authHelpers from 'utils/authHelpers';

function MicroAccountPage() {
  const [userInformation, setUserInformation] = React.useState(null);
  const history = useHistory();
  
  React.useEffect(() => {
    API.getMicroAccount()
      .then((response) => {
        setUserInformation(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleLogout = () => {
    authHelpers.logout();
    history.push('/');
  };

  if (!userInformation || !userInformation.created_at) {
    return (
      <div style={{ padding: '2em', textAlign: 'center' }}>
        <Icon loading name='spinner' size='large' />
        <p>Cargando información...</p>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '1em', marginTop: '1.5em' }}>
      {/* Profile Section */}
      <Card fluid>
        <Card.Content>
          <List>
            <List.Item>
              <List.Icon name='user' />
              <List.Content>
                <List.Header>Nombre</List.Header>
                <List.Description>{userInformation.name}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='mail' />
              <List.Content>
                <List.Header>Correo</List.Header>
                <List.Description>{userInformation.email}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Card.Content>
      </Card>

      {/* Logout Button */}
      <Button 
        fluid 
        color="red" 
        onClick={handleLogout}
        style={{ marginTop: '1em' }}
      >
        <Icon name="sign-out" />
        Cerrar sesión
      </Button>
    </div>
  );
}

export default MicroAccountPage;


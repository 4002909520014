import { signInWithGoogle, signOut as firebaseSignOut } from './firebase';
import API from './api';

const tokenKey = 'restauranterotkn';

const authHelpers = {
  login: (token) => {
    if (token) {
      window.localStorage.setItem(tokenKey, token);
    }
  },
  
  loginWithGoogle: async () => {
    try {
      // Sign in with Google and get user data
      const user = await signInWithGoogle();
      
      // Get the Firebase ID token
      const idToken = await user.getIdToken();
      
      // Exchange Firebase token for our backend token
      const response = await API.verifyFirebaseToken({ 
        idToken,
        email: user.email,
        name: user.displayName
      });
      
      // Store our application token from backend
      const { token } = response.data;
      window.localStorage.setItem(tokenKey, token);
      
      return user;
    } catch (error) {
      console.error('Error logging in with Google:', error);
      throw error;
    }
  },
  
  logout: async () => {
    try {
      // Sign out from Firebase
      await firebaseSignOut();
      // Remove local token
      window.localStorage.removeItem(tokenKey);
    } catch (error) {
      console.error('Error logging out:', error);
      throw error;
    }
  },
  
  getToken: () => {
    return window.localStorage.getItem(tokenKey);
  },
  
  getAuthHeaders: () => {
    const token = authHelpers.getToken();
    return {
      headers: { 'Authorization': `Token ${token}` } 
    }
  },
  
  isAuthenticated: () => {
    // Check if there's a token stored in localStorage
    const token = window.localStorage.getItem(tokenKey);
    return token !== null && token !== undefined && token !== '';
  }
}

export default authHelpers;
import React, { useState } from 'react';
import { Button, Icon, Card } from 'semantic-ui-react';
import Message from 'components/Message';

export default function BillProducts({ products, toggleProductReadyFn, pendingOrders }) {

  const toggleProductReadyStatus = (product) => {
    toggleProductReadyFn(product.id);
  }
  
  if (!products || products.length === 0) {
    return (
      <Message>
        Aún no tienes ordenes en esta cuenta. Para agregar una nueva orden, dale click al botón de "+".
      </Message>
    )
  }

  let summary = null;

  if (pendingOrders.length === 0) {
    summary = (
      <span style={{ fontSize: '1.2em', marginRight: '5px' }}>
        Sin ordenes pendientes
      </span>
    )
  }

  if (pendingOrders.length === 1) {
    summary = (
      <span style={{ fontSize: '1.2em', marginRight: '5px' }}>
        <span style={{ fontWeight: 'bold' }}>1</span> orden pendiente
      </span>
    )
  }
  
  if (pendingOrders.length > 1) {
    summary = (
      <span style={{ fontSize: '1.2em', marginRight: '5px' }}>
        <span style={{ fontWeight: 'bold' }}>{pendingOrders.length}</span> ordenes pendientes
      </span>
    )
  }
  
  return (
    <Card fluid style={{ marginBottom: '2em' }}>
      <Card.Content>
        <Card.Header>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' ,margin: '0.5em 0 1em 0' }}>
              { summary }
              { pendingOrders.length === 0 &&
                <Icon name="check" size="large" color="green" />
              }
          </div>
          
        </Card.Header>
        <Card.Description>
          { products.map((product) => {
            const textDecorationStyle = product.delivered_at ? 'line-through' : 'none';
            const textColorStyle = product.delivered_at ? 'darkgray' : '';
            
            return (
              <div key={product.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1em' }}>
                <p style={{ marginBottom: 0, color: textColorStyle, textDecoration: textDecorationStyle, fontWeight: 'bold' }}>
                  <b style={{ marginRight: '0.5em', fontSize: '20px' }}>
                    { product.quantity }
                  </b>
                  <span style={{ fontSize: '18px' }}>
                    { product.product.name }
                  </span>
                  {/* (product.delivered_at !== null) &&
                    <span style={{ marginLeft: '0.5em' }}>
                      <Icon name="check" size="large" color="grey" />
                    </span>
                  */}
                </p>
                <span style={{ paddingLeft: '1em' }}>
                  { (product.delivered_at === null) ? (
                    <Button
                      icon
                      circular
                      onClick={ () => toggleProductReadyStatus(product) }
                    >
                      <Icon name="check" />
                    </Button>
                  ) : (
                    <Button
                      icon
                      circular
                      onClick={ () => toggleProductReadyStatus(product) }
                    >
                      <Icon name="close" />
                    </Button>
                  )}
                </span>
              </div>
            )
          })}
        </Card.Description>
      </Card.Content>
    </Card>
  )
}
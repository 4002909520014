import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Message, Loader } from 'semantic-ui-react';
import API from 'utils/api';
import authHelpers from 'utils/authHelpers';

export default function AuthPage() {
  const [error, setError] = useState(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const verifyToken = async () => {
      // Get token from URL query params
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      if (!token) {
        setError('Enlace inválido. Por favor solicita un nuevo enlace de acceso.');
        return;
      }

      try {
        const response = await API.verifyMagicLink(token);
        const { token: authToken } = response.data;
        
        // Save the token and redirect
        authHelpers.login(authToken);
        history.push('/bills');
      } catch (error) {
        setError(
          error.response?.data?.message || 
          'El enlace ha expirado o es inválido. Por favor solicita un nuevo enlace de acceso.'
        );
      }
    };

    verifyToken();
  }, [history, location]);

  if (error) {
    return (
      <div style={{ maxWidth: '400px', margin: '2em auto', padding: '0 1em' }}>
        <Message negative>
          <Message.Header>Error de autenticación</Message.Header>
          <p>{error}</p>
          <button 
            onClick={() => history.push('/login')}
            style={{ 
              marginTop: '1em',
              padding: '0.5em 1em',
              cursor: 'pointer'
            }}
          >
            Volver al inicio de sesión
          </button>
        </Message>
      </div>
    );
  }

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      minHeight: '100vh',
      flexDirection: 'column'
    }}>
      <Loader active inline='centered' size='large' />
      <p style={{ marginTop: '1em' }}>Verificando...</p>
    </div>
  );
}
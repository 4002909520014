import axios from 'axios';
import authHelpers from 'utils/authHelpers';
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const API = {
  
  // auth
  requestMagicLink: (params) => {
    const url = `${baseUrl}/auth/magic-link`;
    return axios.post(url, params);
  },

  requestLoginLink: (params) => {
    const url = `${baseUrl}/auth/login`;
    return axios.post(url, params);
  },

  verifyMagicLink: (token) => {
    const url = `${baseUrl}/auth/verify`;
    return axios.post(url, { token });
  },
  
  verifyFirebaseToken: (params) => {
    const url = `${baseUrl}/auth/firebase`;
    return axios.post(url, params);
  },
  
  
  // owner product categories
  
  getProductCategories: () => {
    const url = `${baseUrl}/owners/product_categories`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  createProductCategory: (params) => {
    const url = `${baseUrl}/owners/product_categories`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },

  // owner products
  createProduct: (params) => {
    const url = `${baseUrl}/owners/products`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },
  
  getProducts: () => {
    const url = `${baseUrl}/owners/products`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  // owner bills
  getBills: () => {
    let url = `${baseUrl}/owners/bills`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  createBill: (params) => {
    const url = `${baseUrl}/owners/bills`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },

  getBill: (billId) => {
    const url = `${baseUrl}/owners/bills/${billId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  addOrders: (params, billId) => {
    const url = `${baseUrl}/owners/bills/${billId}/add_orders`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },

  toggleProductReady: (productId) => {
    const url = `${baseUrl}/owners/orders/${productId}/ready`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },

  billPaid: (billId) => {
    const url = `${baseUrl}/owners/bills/${billId}/paid`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },

  billDelivered: (billId) => {
    const url = `${baseUrl}/owners/bills/${billId}/delivered`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },
  
  deleteBill: (billId) => {
    const url = `${baseUrl}/owners/bills/${billId}/delete`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, {}, headers)
  },
  
  
  
  
  getProduct: (productId) => {
    const url = `${baseUrl}/owners/products/${productId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },
  
  
  
  
  
  
  
  toggleMicroProductAvailability: (productId) => {
    const url = `${baseUrl}/micros/products/${productId}/availability`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, {}, headers)
  },

  getOwnerWeeklyReports: (date) => {
    let url = `${baseUrl}/owners/sales/weekly`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getOwnerMonthlyReports: (establishmentId, date) => {
    const url = `${baseUrl}/owners/sales/monthly`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getOwnerDailyReports: (date) => {
    let url = `${baseUrl}/owners/sales/daily`;
    if (date) {
      const formattedDate = encodeURIComponent(date.toISOString());
      url += `?date=${formattedDate}`;
    }
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getOwnerEstablishmentSales: (establishmentId, date) => {
    let url = `${baseUrl}/owners/sales/${establishmentId}`;
    if (date) {
      const formattedDate = encodeURIComponent(date.toISOString());
      url += `?date=${formattedDate}`;
    }
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getOwnerClosedBills: (date) => {
    let url = `${baseUrl}/owners/closed-bills`;
    if (date) {
      const formattedDate = encodeURIComponent(date.toISOString());
      url += `?date=${formattedDate}`;
    }
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  // MICRO
  

  generateStripeCheckoutSessionId: (params) => {
    const url = `${baseUrl}/owners/account/susc/${params.planType}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },

  

  addOrUpdateProducts: (params, billId) => {
    const url = `${baseUrl}/micros/bills/${billId}/orders`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },

  

  getMicroAccount: () => {
    const url = `${baseUrl}/owners/account`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroSalesByWeek: () => {
    const url = `${baseUrl}/micros/closed-bills/weekly`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroSalesByMonth: () => {
    const url = `${baseUrl}/micros/closed-bills/monthly`;
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  

  

  

  

  

  createMicroProductCategory: (params) => {
    const url = `${baseUrl}/micros/product_categories`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },

  updateMicroEstablishmentProduct: (productId, params) => {
    const url = `${baseUrl}/micros/products/${productId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },

  archiveMicroProduct: (productId) => {
    const url = `${baseUrl}/micros/products/${productId}/archive`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, {}, headers)
  },

  updateBillName: (billId, params) => {
    const url = `${baseUrl}/micros/bills/${billId}/name`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },

  updateCategoryName: (categoryId, params) => {
    const url = `${baseUrl}/micros/product_categories/${categoryId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },

  

  deleteCategory: (categoryId) => {
    const url = `${baseUrl}/micros/product_categories/${categoryId}/delete`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, {}, headers)
  },

  

  

  getMicroExpense: (expenseId) => {
    let url = `${baseUrl}/micros/expenses/${expenseId}`;    
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  getMicroExpenses: (date) => {
    let url = `${baseUrl}/micros/expenses`;
    if (date) {
      const formattedDate = encodeURIComponent(date.toISOString());
      url += `?date=${formattedDate}`;
    }
    const headers = authHelpers.getAuthHeaders();
    return axios.get(url, headers)
  },

  createMicroExpense: (params) => {
    const url = `${baseUrl}/micros/expenses`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, params, headers)
  },
  updateMicroExpense: (expenseId, params) => {
    const url = `${baseUrl}/micros/expenses/${expenseId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.put(url, params, headers)
  },
  deleteMicroExpense: (expenseId) => {
    const url = `${baseUrl}/micros/expenses/${expenseId}`;
    const headers = authHelpers.getAuthHeaders();
    return axios.post(url, {}, headers)
  }
};

export default API;

import React from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListIcon, ListContent } from 'semantic-ui-react';

export default function MenuCategoryRow(props) {
  return (
    <ListItem>
      <ListContent>
        { props.category.name }
      </ListContent>
    </ListItem>
  )
}
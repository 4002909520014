import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Icon, List, Grid, Card, Button, Label } from 'semantic-ui-react';
import Header from 'components/Header';
import Message from 'components/Message';
import CancelGoButtons from 'components/CancelGoButtons';
import BillProducts from './ui/BillProducts';
import BillSummary from './ui/BillSummary';
import API from 'utils/api';
import formatter from 'utils/formatter';
import AddOrdersMenu from './ui/AddOrdersMenu';
import TimeAgo from 'timeago-react';
import { register } from 'timeago.js';
import es from 'timeago.js/lib/lang/es';

class BillPage extends React.Component {
  constructor(props) {
    super(props);

    // Register the Spanish locale
    register('es', es);

    this.state = {
      billId: null,
      bill: {
        name:"",
        orders: []
      },
      ordersInMenu: [],
      showBillReceipt: false,
      showBillDeliveredConfirmation: false,
      showBillNameEdition: false,
      showMenu: false,
    };
  }
  
  componentDidMount() {
    const billId = parseInt(this.props.match.params.billId);
    this.setState({ billId }, this.getBill);
  }

  getBill = () => {
    API.getBill(this.state.billId)
      .then((response) => {
        this.setState({
          bill: response.data,
          ordersInMenu: response.data.orders
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  toggleProductReady = (productId) => {
    API.toggleProductReady(productId)
      .then((response) => {
        this.getBill();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  billPaid = () => {
    API.billPaid(this.state.billId)
      .then((response) => {
        this.setState({
          showBillReceipt: false
        }, this.getBill);
      })
      .catch((error) => {
        console.error(error);
      })
  }

  deliverBill = () => {
    this.setState({ showBillDeliveredConfirmation: true });
  }

  billDelivered = () => {
    API.billDelivered(this.state.billId)
      .then((response) => {
        this.props.history.push('/bills');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  showBillNameEdition = () => {
    this.setState({showBillNameEdition: true})
  }

  hideBillNameEdition = () => {
    this.setState({showBillNameEdition: false})
  }

  handleBillName = (event) => {
    let newName = event.target.value;
    console.log("New bill name");
    console.log(newName);
    this.setState(prevState => ({
        bill: {
            ...prevState.bill,
            name: newName
        }
    }));
  }

  updateBillName = () => {
    const params = {
      name: this.state.bill.name
    }
    API.updateBillName(this.state.billId, params)
      .then((response) => {
        console.log(response);
        this.updateBillName();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteBill = () => {
    API.deleteBill(this.props.match.params.billId)
      .then((response) => {
        this.props.history.push('/bills');
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  render() {
    if (!this.state.bill) { return null }

    const pendingOrders = this.state.bill.orders.filter(order => !order.delivered_at);

    // when an order has been closed (paid and delivered)
    if (this.state.bill.paid_at && this.state.bill.delivered_at) {
      return (
        <div>
          <div>
            <Header text={`#${this.state.billId}`} subtitle={this.state.bill.note} inlineSubtitle={true} />
          </div>

          <div style={{ marginBottom: '1em', fontSize: '24px' }}>
            { formatter.currency(this.state.bill.total) }
          </div>
        
          <Card fluid>
            <Card.Content>
              <List divided relaxed>
                { this.state.bill.orders.map((order) => 
                  <List.Item key={order.id}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={12} verticalAlign="top">
                          <p style={{ fontSize: '1em', marginBottom: 0 }}>
                            { order.product.name }
                          </p>
                          { (order.quantity > 1) &&
                            <p style={{ fontSize: '1em', marginBottom: 0 }}>
                              x {order.quantity}
                            </p>
                          }
                        </Grid.Column>
                        
                        <Grid.Column width={4} verticalAlign="top" textAlign="right">
                          <p style={{ fontSize: '1em', marginBottom: 0, color: (order.quantity > 1 ? 'gray' : '') }}>
                            { formatter.currency(order.product.price) }
                          </p>
                          { (order.quantity > 1) &&
                            <p style={{ fontSize: '1em', marginBottom: 0 }}>
                              {formatter.currency((order.quantity * order.product.price))}
                            </p>
                          }
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </List.Item>
                )}
              </List>
            </Card.Content>
          </Card>

          <div style={{ marginBottom: '0.5em', marginTop: '1.5em', color: 'gray' }}>
            <span>
              <Icon name="clock outline" size='large' />
              <span style={{ marginRight: '5px'}}>
                Entregado
              </span>
              <TimeAgo
                datetime={this.state.bill.delivered_at}
                locale="es"
              />
            </span>
          </div>

          <div style={{ marginBottom: '0.5em', color: 'gray' }}>
            <span>
              <Icon name="clock outline" size='large' />
              <span style={{ marginRight: '5px'}}>
                Pagado
              </span>
              <TimeAgo
                datetime={this.state.bill.paid_at}
                locale="es"
              />
            </span>
          </div>

          <div style={{  color: 'gray' }}>
            <span>
              <Icon name="clock outline" size='large' />
              <TimeAgo
                datetime={this.state.bill.created_at}
                locale="es"
              />
            </span>
          </div>
          
          <Button fluid style={{ marginTop: '2em' }} as={Link} to="/bills">
            regresar
          </Button>
        </div>
      )
    }

    // when an order is being paid
    if (this.state.showBillReceipt) {
      return (
        <BillSummary
          bill={this.state.bill}
          orders={this.state.bill.orders}
          cancelFn={ () => this.setState({ showBillReceipt: false }) }
          billPaidFn={this.billPaid}
        />
      )
    }

    // when an order is being delivered
    if (this.state.showBillDeliveredConfirmation) {
      const formattedTotal = formatter.currency(this.state.bill.total);
      const customerName = this.state.bill.name || this.state.bill.note;
      const totalOrders = this.state.bill.orders.length;
      
      return (
        <div style={{ marginTop: '1em' }}>
          <Message headerText="Cerrar cuenta">
            <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '1.5em' }}>
              ¿Estás seguro de cerrar la siguiente cuenta?
            </p>
            
            <p style={{ textAlign: 'center', fontSize: '1.2em', marginBottom: '1.5em' }}>
              Cuenta <b>#{this.state.bill.id}</b> con <b>{totalOrders}</b> {totalOrders === 1 ? 'artículo' : 'artículos'}
              <br />
              <span style={{ fontSize: '2em', fontWeight: 'bold', display: 'block', margin: '0.5em 0' }}>
                {formattedTotal}
              </span>
            </p>

            <p style={{ textAlign: 'center', color: '#666', fontSize: '1em', marginBottom: '1.5em' }}>
              Al cerrar la cuenta, esta no podrá modificarse.
            </p>
  
            <CancelGoButtons
              cancelFn={ () => this.setState({ showBillDeliveredConfirmation: false }) }
              goText="cerrar cuenta"
              goEnabled={true}
              goFn={this.billDelivered}
              warning={true}
            />
          </Message>
        </div>
      )
    }

    // Display menu to add orders
    if (this.state.showMenu) {
      return (
        <AddOrdersMenu
          cancelFn={ () => this.setState({ showMenu: false }) }
          billId={this.state.billId}
          getBill={this.getBill}
        />        
      );
    }

    let actionButtons = (
      <CancelGoButtons
        cancelFn={ () => this.props.history.push('/bills') }
        cancelText="regresar"
        goText="eliminar cuenta"
        goEnabled={true}
        warning
        goFn={this.deleteBill}
      />
    );

    if (this.state.bill.orders.length > 0) {
      actionButtons = (
        <CancelGoButtons
          cancelFn={ () => this.props.history.push('/bills') }
          cancelText="regresar"
          goText="cobrar cuenta"
          goEnabled={true}
          goFn={ () => this.setState({ showBillReceipt: true }) }
        />
      );

      if (this.state.bill.paid_at) {
        actionButtons = (
          <CancelGoButtons
            cancelFn={ () => this.props.history.push('/bills') }
            cancelText="regresar"
            goText="cerrar cuenta"
            goEnabled={pendingOrders.length === 0}
            goFn={this.deliverBill}
          />
        );
      }
    }
    
    return (
      <div style={{ paddingBottom: '2em' }}>
        <div style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}}>
          <Header
            text={this.state.bill.name ? this.state.bill.name : "Cuenta #" + this.state.bill.id}
            functionToExecute={this.showBillNameEdition}
          />
          <Button circular icon primary onClick={ () => this.setState({ showMenu: true })} >
            <Icon name="plus" />
          </Button>
        </div>

        <div style={{ marginBottom: '1em', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <span style={{ fontSize: '2.5em', fontWeight: 'bold', marginRight: '10px' }}>
            { formatter.currency(this.state.bill.total) }
          </span>
          <div>
            { this.state.bill.paid_at ? (
              <Label color="green">
                PAGADA
              </Label>
              
            ) : (
              <Label color="red">
                SIN PAGAR
              </Label>
            )}
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: '', alignItems: 'center', marginBottom: '2em' }}> 
          <Icon name="clock outline" size='large' />
          <span style={{ fontSize: '1.2em',  }}>
            Abierta
            {' '}
            <TimeAgo
              datetime={this.state.bill.created_at}
              locale="es"
            />
          </span>
        </div>

        <BillProducts
          products={this.state.bill.orders}
          toggleProductReadyFn={ (orderId) => this.toggleProductReady(orderId) }
          deliverBillFn={ (billId) => this.deliverBill(billId) }
          pendingOrders={pendingOrders}
        />

        { actionButtons }
      </div>
    )
  }
}

export default withRouter(BillPage);


{/* <div style={{display:'flex', marginTop:'1em', justifyContent: 'center'}}>
          {!this.state.bill.paid_at && !anyOrderReady && (            
            <Button color='red' onClick={() => this.setState({ showDeleteWarningMessage: true })} style={{marginRight:'0.25em', width:'100%'}}>
              Eliminar Cuenta
            </Button>
          )}          
        </div> */}